<template>
  <div>
    <b-card title="Busqueda de paciente">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Tipo de documento*"
            label-for="document_type"
          >
            <v-select
              v-model="document_type_id"
              label="text"
              placeholder="Seleccione tipo de documento"
              :options="documentTypes"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Número de documento"
            label-for="document_number"
          >
            <b-form-input
              id="document_number"
              v-model="document_number"
              placeholder="Número de documento"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="handleSearchPatient"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="search"
            />
            Buscar paciente
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
	BCard,
	BButton,
	BFormGroup,
	BFormInput,
	BSpinner,
	BRow,
	BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'

export default {
	inject: ['patientsRepository', 'datafieldsRepository'],
	components: {
		BCard,
		BButton,
		BFormInput,
		BFormGroup,
		BSpinner,
		BRow,
		BCol,
		vSelect,
	},
	data() {
		return {
      locationId: this.$route.params.locationId,
			document_type_id: null,
			document_number: '',
			documentTypes: [],
			isBusy: false,
		}
	},
	async mounted() {
		const me = this
		const documentTypes = await me.datafieldsRepository.getAll('document_type')
		me.documentTypes = documentTypes.data.map(item => ({
			value: item.id,
			text: item.longName,
		}))
	},
	validations: {
		document_type_id: {
			required,
		},
		document_number: {
			required,
		},
	},
	methods: {
		async handleSearchPatient() {
			const me = this
			try {
				me.isBusy = true
				const res = await me.patientsRepository.findPatientByNumberDocument(me.document_number)
				const { data } = res.data
				if (data.length > 0) {
					this.$router.push({
						name: 'register-paciente',
						params: { locationId: me.locationId, patientId: data[0].id },
					})
				} else {
					this.$router.push({
						name: 'register-paciente',
						params: { locationId: me.locationId, patientId: 'null' },
					})
				}
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
